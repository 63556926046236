import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import service from '../assets/images/articles/service.png'
import appshow from '../assets/images/articles/appshow.png'
import dabbawalla from '../assets/images/articles/dabbawalla.png'
import post from '../assets/images/articles/post.png'

const MyThinking = props => (
  <Layout>
    <Helmet>
      <title>Generic - Forty by HTML5 UP</title>
      <meta name="description" content="Generic Page" />
    </Helmet>

    <div id="main" className="alt">
      <section id="title-section" className="title-block inner">
        <div className="hp-featured">
          <h1 className="hp-featured__title">
            my <br />
            thinking.
          </h1>
          <div className="hp-featured__content">
            <div className="hp-featured__hr-line" />
            <p className="hp-featured__text">
              Below i have shared some of my opinions on different topics which
              offer insights on how I think as a designer. Watch this space for
              more of my views as i continue my design journey.
            </p>
          </div>
        </div>
      </section>

      <section id="articles" className="articles inner">
        <div class="wrapper-articles">
          <div class="articles-wrap">
            <div class="col1">
              <div class="read-time">3 min read</div>
            </div>
            <div class="col2">
              <div class="article-card">
                <div class="published">DECEMBER 2017</div>
                <div class="title">
                  <a href="/articles/designing-mobile-app-for-iot-products">
                    designing apps for IoT products.
                  </a>
                </div>
                <div class="text">
                  The continued growth of mobile devices has led to an increase
                  in ubiquitous use of mobile apps. The transient needs of
                  consumer call for personalisation […]
                </div>
                <a href="/articles/designing-mobile-app-for-iot-products">
                  <div class="image">
                    <img src={appshow} alt="" />
                  </div>
                </a>
              </div>
              <div class="article-card">
                <div class="published">JANUARY 2016</div>
                <div class="title">
                  <a href="/articles/service-design-post-its">
                    service design & post-its
                  </a>
                </div>
                <div class="text">
                  Nothing says creativity better than a crazy colourful mess of
                  Post-its smeared onto the wall rather than a bunch of people
                  with suits sitting […]
                </div>
                <a href="/articles/service-design-post-its">
                  <div class="image">
                    <img src={post} alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="articles-wrap">
            <div class="col1">
              <div class="read-time">8 min read</div>
            </div>
            <div class="col2">
              <div class="article-card">
                <div class="published">SEPTEMBER 2016</div>
                <div class="title">
                  <a href="/articles/tools-for-designing-services">
                    tools for designing services.
                  </a>
                </div>
                <div class="text">
                  Introduction of service design tools to a group of Engineers,
                  Business & Marketing professionals. Guiding them through a
                  workshop on how these tools […]
                </div>
                <a href="/articles/tools-for-designing-services">
                  <div class="image">
                    <img src={service} alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="articles-wrap">
            <div class="col1">
              <div class="read-time">12 min read</div>
            </div>
            <div class="col2">
              <div class="article-card">
                <div class="published">MARCH 2017</div>
                <div class="title">
                  <a href="/articles/service-with-touchpoints">
                    a service with 5000 touchpoints
                  </a>
                </div>
                <div class="text">
                  Meet the dabbawallas of Mumbai, one of the most efficient
                  systems in the world, a Harvard case study, certified Six
                  Sigma by Forbes. Delivering over […]
                </div>
                <a href="/articles/service-with-touchpoints">
                  <div class="image">
                    <img src={dabbawalla} alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="footertopcta" className="inner">
        <p className="text arrow-front">
          now that you have read about my views,
          <br /> find out more about <a href="/what-offer">what i offer</a>
        </p>
      </section>
    </div>
  </Layout>
)

export default MyThinking
